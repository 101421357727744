import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as Style from "./contact.module.css";

function Mobile({
  name,
  company,
  email,
  phone,
  city,
  message,
  addressLinks,
  handleChange,
  onSubmit
}) {
  const [currAddr, setCurrAddr] = useState(addressLinks["pune"]);

  return (
    <div className="bg-heading-color pt-5">
      <div className={`${Style.main} ${Style.bgProp}`}>
        <div className="pt-14" />
        <div
          className="conainter m-auto"
          style={{
            width: "80%"
          }}>
          <h1
            className="font-OswaldMedium text-custom-brown mb-8"
            style={{
              fontSize: "7vw"
            }}>
            We'd Love To
            <br className="m-2 mb-1" /> Hear From You!
          </h1>
          <div className="mb-8">
            <StaticImage
              src="../../assets/images/contact/AI_Image.tif"
              formats={["auto", "webp", "avif", "png"]}
              className="h-1/3 w-11/12"
              alt="Site Logo"
            />
          </div>
          <div>
            <form className="w-5/6" onSubmit={onSubmit}>
              <div className="flex justify-between flex-wrap">
                <div
                  className={`border-custom-yellow ${Style.mobileInputDiv} w-full`}>
                  <input
                    className="text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="Name *"
                    required
                    name="name"
                    value={name}
                    aria-label="full name"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`border-custom-yellow ${Style.mobileInputDiv} w-full`}>
                  <input
                    className="text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="Company *"
                    required
                    name="company"
                    value={company}
                    aria-label="company name"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`border-custom-yellow ${Style.mobileInputDiv} w-full`}>
                  <input
                    className="text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="Email *"
                    required
                    name="email"
                    value={email}
                    aria-label="email"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`border-custom-yellow ${Style.mobileInputDiv} w-full`}>
                  <input
                    className="text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="Phone *"
                    required
                    value={phone}
                    name="phone"
                    aria-label="contact number"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={`border-custom-yellow ${Style.mobileInputDiv} w-full`}>
                  <input
                    className="text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="City *"
                    required
                    name="city"
                    value={city}
                    aria-label="city"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="my-5">
                <div className="sm:flex-col flex flex-wrap justify-between text-custom-teal-body">
                  <span>I'm</span>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 border border-custom-yellow bg-white checked:bg-custom-brown checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="radioOption"
                      value="an architect"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label inline-block"
                      htmlFor="inlineRadio10">
                      an architect
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 border border-custom-yellow bg-white checked:bg-custom-brown checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="radioOption"
                      value="a dealer"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label inline-block"
                      htmlFor="inlineRadio20">
                      a dealer
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 border border-custom-yellow bg-white checked:bg-custom-brown checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="radioOption"
                      value="a customer"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label inline-block"
                      htmlFor="inlineRadio20">
                      a customer
                    </label>
                  </div>
                </div>
              </div>
              <textarea
                className="border border-solid border-custom-brown bg-transparent w-full my-8 p-3"
                id="message"
                name="message"
                value={message}
                onChange={handleChange}
                rows={6}
                placeholder="Write message"
              />
              <button
                type="submit"
                className="uppercase px-6 py-2 text-sm text-custom-teal-body border-2 border-custom-teal-body">
                Send
              </button>
            </form>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-16 pb-16">
            <div>
              <div
                className="hover: cursor-pointer"
                onClick={() => setCurrAddr(addressLinks["pune"])}>
                <h2 className={`${Style.addressHeading} text-custom-teal text-lg`}>
                  Pune
                </h2>
                <div className={`${Style.addressContent} text-custom-brown`}>
                  929/AFP 313, Mantri House FC road,
                  <br /> Shivajinagar,
                  <br />
                  Basement no.5,
                  <br />
                  Pune - 411005
                </div>
              </div>
              <div
                className="hover: cursor-pointer"
                onClick={() => setCurrAddr(addressLinks["nashik"])}>
                <h2
                  className={`${Style.addressHeading} text-custom-teal mt-8 text-lg`}>
                  Nashik
                </h2>
                <div className={`${Style.addressContent} text-custom-brown`}>
                  Shop No. 15, Palika Market,
                  <br /> Opp HDFC House,
                  <br />
                  Sharanpur Road,
                  <br />
                  Nashik - 422002
                </div>
              </div>
            </div>
            <div>
              <div
                className="hover: cursor-pointer"
                onClick={() => setCurrAddr(addressLinks["mumbai"])}>
                <h2 className={`${Style.addressHeading} text-custom-teal text-lg`}>
                  Mumbai
                </h2>
                <div className={`${Style.addressContent} text-custom-brown`}>
                  Office no-112, Kuber Complex,
                  <br /> Andheri Link road,
                  <br />
                  Andheri West,
                  <br />
                  Mumbai - 400053
                </div>
              </div>
              <div className="text-custom-teal-body font-sans mt-14 mb-1">
                Give Us A Call
              </div>
              <a href="tel:9662427575" className="text-custom-brown font-sans">
                +91 96624 27575
              </a>
              <div className="text-custom-teal-body font-sans mt-6 mb-1">
                Write To Us
              </div>
              <a
                href="mailto:info@smiithandmould.in"
                className="text-custom-brown font-sans">
                info@smiithandmould.in
              </a>
            </div>
          </div>
          <div className="mb-16">
            <iframe
              className="w-full"
              title="MAP"
              src={currAddr}
              height={400}
              frameBorder={0}
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobile;
