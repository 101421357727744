import React from "react";
import DesktopNav from "./desktop";
import MobileNav from "./mobile";

const Navbar = () => {
  return (
    <div>
      <DesktopNav />
      <MobileNav />
      <div className="pb-24" />
    </div>
  );
};

export default Navbar;
