import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import LogoImage from "../../assets/images/home/logo.svg";

const MobileNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <div className="fixed w-full z-50">
      <div className="lg:hidden bg-custom-teal p-4">
        <div onClick={handleMenu} className=" absolute top-10 left-8">
          <svg viewBox="0 0 110 80" width="30" height="30" fill="#E0B65F">
            <rect width="100" height="10" />
            <rect y="30" width="80" height="10" />
            <rect y="60" width="60" height="10" />
          </svg>
        </div>
        <div className="flex justify-center">
          <Link to="/#home">
            <img src={LogoImage} alt="Site Logo" className="w-52 m-0 p-0" />
          </Link>
        </div>
        {isMenuOpen ? (
          <div className="z-10 absolute top-24 left-0 w-48 bg-custom-teal pt-2 pl-1 pr-2 pb-2">
            <ul className="text-lg font-OswaldRegular text-custom-brown">
              <Link to="/#products">
                <li className="p-2">PRODUCTS</li>
              </Link>
              <Link to="/works">
                <li className="p-2">WORK</li>
              </Link>
              <Link to="/about">
                <li className="p-2">WHO WE ARE</li>
              </Link>
              <Link to="/contact-us">
                <li className="p-2">CONNECT</li>
              </Link>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MobileNav;
