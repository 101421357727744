import * as React from "react";

import Seo from "../components/seo";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const ContactUs = () => (
  <>
    <Seo title="Smiith and Mould: Contact Us" />
    <Navbar />
    <Contact />
    <Footer />
  </>
);

export default ContactUs;
