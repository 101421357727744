import React, { Component } from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company: "",
      email: "",
      phone: "",
      city: "",
      radioOption: "",
      message: "",
      addressLinks: {
        pune: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.067252413295!2d73.83909741544252!3d18.525862787405977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf807c6be94d%3A0x3a49fd3ec6106dbb!2sMantri%20House%2C%20Fergusson%20College%20Rd%2C%20Shivajinagar%2C%20Pune%2C%20Maharashtra%20411004!5e0!3m2!1sen!2sin!4v1641558980803!5m2!1sen!2sin",
        mumbai:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.2328939329864!2d72.8291745154498!3d19.141280187050498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73f095ce8ed%3A0x6bb63e63bffbda45!2sKuber%20Complex!5e0!3m2!1sen!2sin!4v1641559129186!5m2!1sen!2sin",
        nashik:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.180958072673!2d73.77538771546052!3d20.000918486564075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba0c508d3eb%3A0x718d1d6fddbead2e!2sSharanpur%20Rd%2C%20Nashik%2C%20Maharashtra%20422002!5e0!3m2!1sen!2sin!4v1641559344906!5m2!1sen!2sin"
      }
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  onSubmit = (event) => {
    event.preventDefault();
    event.persist();
    console.log(this.state);
  };
  render() {
    return (
      <div>
        <div className="md:hidden">
          <Desktop
            name={this.state.name}
            company={this.state.company}
            email={this.state.email}
            phone={this.state.phone}
            city={this.state.city}
            message={this.state.message}
            addressLinks={this.state.addressLinks}
            handleChange={this.handleChange}
            onSubmit={this.onSubmit}
          />
        </div>
        <div className="lg:hidden">
          <Mobile
            name={this.state.name}
            company={this.state.company}
            email={this.state.email}
            phone={this.state.phone}
            city={this.state.city}
            message={this.state.message}
            addressLinks={this.state.addressLinks}
            handleChange={this.handleChange}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

export default ContactUs;
